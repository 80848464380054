/* eslint-disable react-hooks/exhaustive-deps */
import React, {
    memo,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

// @Material-ui
import {
    FormControl,
    FormControlLabel,
    Icon,
    MenuItem,
    useMediaQuery,
} from '@material-ui/core'
import Grid from '@mui/material/Grid'

// @Components
import RegisterInput, {
    DateMaskCustom,
    ResponsableCPF,
    ResponsableCPFAndCNPJ,
} from 'components/RegisterInput'
import {
    CustomEndAdornment,
    SmToolTip,
} from '../../../../components/RegisterInput/styles'
import CustomSelect from '../../../../components/CustomSelect'

// @Helpers
import {
    GetPreConsumerByToken,
    ValidateDocument,
} from '../../../../redux/api/user'
import { initialFormValues } from '../../helpers/initialValues'
import {
    errorTreatment,
    MASKSCNPJ,
    onBlurStateRegistration,
} from './helpers/errorsTreatment'
import * as Style from './styles'

// @Utils
import {
    CONSUMER_ALREADY_ACTIVE,
    CONSUMER_ALREADY_ACTIVE_CNPJ,
    CPF_NOT_ALLOWED,
    DUPLICATED,
    DUPLICATED_CNPJ,
    EMAIL_DOES_NOT_VALIDATED,
    fillPreForm,
    PHONENUMBER_DOES_NOT_VALIDATED,
    PHONENUMBER_DUPLICATED,
    replaceCpfCnpj,
} from '../../helpers/utils'
import GeneralContext from '../../../../Context'
import { CustomCheckbox } from '../../../../components/CustomCheckbox'
import { GetThemeColorFromStorage } from '../../../../redux/api/themeColor'
import { GetTenantVersion } from '../../../../redux/api/About'

function PersonalData({
    errors,
    control,
    reset,
    watch,
    userCpf,
    setUserCpf,
    setModalAlert,
    setUserData,
    setPreDisabled,
    preDisabled,
    generalRules,
    setIsCnpj,
    ufs,
    setUfs,
    clearErrors,
    setValue,
    setError,
    disableItems,
    token,
    setDisableItems,
}) {
    const { t } = useTranslation()
    const values = watch()

    const { listState } = useContext(GeneralContext)

    const [tenant, setTenant] = useState('')
    const [openTooltip, setOpenTooltip] = useState(false)
    const mdMediaQuery = useMediaQuery('(min-width:960px)')

    const [theme] = useState(GetThemeColorFromStorage())

    const handleCpfRemove = () => {
        setPreDisabled(false)
        setUserCpf({})
        setUfs('')
        setValue('dateOfBirth', '')
        reset(initialFormValues)
        setValue('IsError', false)
        setValue('InvalidEmail', false)
        setDisableItems({
            email: false,
            phone: false,
        })
    }

    const getTenantVersion = async () => {
        try {
            const response = await GetTenantVersion()

            if (response?.status === 200 && response?.data) {
                setTenant(response?.data)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    const replaceStateRegistration = (value) => {
        if (_.isEmpty(value)) return ''

        const stateRegistration = value?.replace(/[^\d]+/g, '')

        return stateRegistration
    }

    function hasMultipleLetters(inscricaoEstadual) {
        if (_.isEmpty(inscricaoEstadual)) return false
        const regex = /[A-Za-z].*[A-Za-z]/
        return regex.test(inscricaoEstadual)
    }

    const validateCpf = useCallback(
        async (value) => {
            if (_.isEmpty(value)) return
            const clearValue = replaceCpfCnpj(value)

            if (clearValue.length === 11 || clearValue.length === 14) {
                const { data, success, errors } = await ValidateDocument(
                    value,
                    true,
                    Boolean(tenant === 'pocketexpress'),
                )
                success && setUserCpf({})

                if (errors?.length) {
                    switch (errors[0].message) {
                        case EMAIL_DOES_NOT_VALIDATED:
                            setModalAlert((prevState) => ({
                                ...prevState,
                                modalResendEmailProps: {
                                    open: true,
                                    email: data?.email,
                                },
                            }))
                            return
                        default:
                            break
                    }

                    if (tenant === 'pocketexpress') {
                        errorTreatment({
                            errors,
                            setState: setModalAlert,
                            t,
                            value,
                            tenant,
                        })

                        if (errors[0].message === '2FA - NOT_FOUND') {
                            setUserCpf({ errors: t(errors[0].message ||'2FA - NOT_FOUND') })
						}
                    }

                    if (tenant !== 'pocketexpress') {
                        errorTreatment({
                            errors,
                            setState: setModalAlert,
                            t,
                            value,
                        })

                        const isCNPJ = replaceCpfCnpj(value)?.length > 11

                        const errorMessage =
                            errors[0]?.message === 'DateOfBirth - NOT_FOUND' &&
                            isCNPJ
                                ? t('Foundation_NOT_FOUND')
                                : t(errors[0].message)

                        if (
                            errors[0].message !== '2FA - NOT_FOUND' &&
                            errors[0]?.message !== 'ZipCode - NOT_FOUND' &&
                            errors[0]?.message !== 'DateOfBirth - NOT_FOUND'
                        ) {
                            setUserCpf({ errors: errorMessage })
                        }

                        switch (errors[0].message) {
                            case EMAIL_DOES_NOT_VALIDATED:
                                setModalAlert((prevState) => ({
                                    ...prevState,
                                    modalResendEmailProps: {
                                        open: true,
                                        email: data?.email,
                                    },
                                }))
                                return
                            case DUPLICATED:
                                setModalAlert((prevState) => ({
                                    ...prevState,
                                    modalProps: {
                                        open: true,
                                        text: t(
                                            'CPF_ALREADY_REGISTERED_IN_THE_PROGRAM',
                                        ),
                                    },
                                }))
                                return
                            case CONSUMER_ALREADY_ACTIVE:
                                setModalAlert((prevState) => ({
                                    ...prevState,
                                    modalProps: {
                                        open: true,
                                        text: t(
                                            'ALREADY_REGISTERED_IN_THE_PROGRAM',
                                        ),
                                    },
                                }))
                                return
                            case CONSUMER_ALREADY_ACTIVE_CNPJ:
                                setModalAlert((prevState) => ({
                                    ...prevState,
                                    modalProps: {
                                        open: true,
                                        text: t(
                                            'ALREADY_REGISTERED_IN_THE_PROGRAM',
                                        ),
                                    },
                                }))
                                return
                            case PHONENUMBER_DUPLICATED:
                                setModalAlert((prevState) => ({
                                    ...prevState,
                                    modalProps: {
                                        open: true,
                                        text: t('DUPLICATED_PHONE_NUMBER'),
                                    },
                                }))
                                return
                            case DUPLICATED_CNPJ:
                                setModalAlert((prevState) => ({
                                    ...prevState,
                                    modalProps: {
                                        open: true,
                                        text: t('DUPLICATED_CNPJ'),
                                    },
                                }))
                                return
                            case PHONENUMBER_DOES_NOT_VALIDATED:
                                setModalAlert((prevState) => ({
                                    ...prevState,
                                    modalResendSmsProps: { open: true },
                                }))
                                return
                            case CPF_NOT_ALLOWED:
                                setModalAlert((prevState) => ({
                                    ...prevState,
                                    modalAdmin: { open: true },
                                }))
                                return
                            default:
                                break
                        }
                    }
                }

                if (data) {
                    setPreDisabled(true)
                    setUserData(data)

                    reset({
                        ...data,
                        cpf:
                            data?.cpf?.length > 11
                                ? MASKSCNPJ(data?.cpf)
                                : data?.cpf,
                        dateOfBirth: data.dateOfBirthMasked,
                        address: {
                            ...data.address,
                            stateId: data?.address?.state?.id || '',
                            cityId: data?.address.city?.id || '',
                            countryId: data?.address?.country?.id || '',
                        },
                        listState: Boolean(data?.stateRegistrationWaiver)
                            ? ''
                            : data?.address?.state?.id,
                        nameRP: data?.representative?.name,
                        cpfRP: data?.representative?.cpf,
                        phoneNumberRP: data?.representative?.phoneNumber,
                        stateRegistration: replaceStateRegistration(
                            data?.stateRegistration,
                        ),
                        emailRP: data?.representative?.email,
                        emailRpPre: data?.representative?.email,
                        stateRegistrationWaiver: Boolean(
                            data?.stateRegistrationWaiver,
                        ),
                        isMEI: Boolean(data?.isMEI),
                        isPreRegistration: 'true',
                        disabledDateOfBirth: !_.isEmpty(
                            data?.dateOfBirthMasked,
                        ),
                        isStateDisabled: hasMultipleLetters(
                            data?.stateRegistration,
                        ),
                        phoneNumber2Pre: _.isEmpty(data?.phoneNumber2)
                            ? null
                            : data?.phoneNumber2,
                        emailPre: _.isEmpty(data?.email)
                            ? undefined
                            : data?.email,
                    })

                    disableContactFields(data)

                    if (tenant !== 'pocketexpress') {
                        for (const error of errors) {
                            const { field, message } = error
                            if (field === 'Email') {
                                setValue('InvalidEmail', true)
                                setDisableItems({
                                    ...disableItems,
                                    email: true,
                                })
                                setError(`email`, {
                                    type: 'manual',
                                    message: t(message),
                                })
                                setUserCpf({})
                            }

                            if (field === 'PhoneNumber2') {
                                setValue('IsError', true)
                                setError(`phoneNumber2`, {
                                    type: 'manual',
                                    message: t(message),
                                })
                                setDisableItems({
                                    ...disableItems,
                                    phone: true,
                                })
                                setUserCpf({})
                            }
                        }
                    }
                }
            }
        },
        [
            reset,
            setError,
            setModalAlert,
            setPreDisabled,
            setUserCpf,
            setUserData,
            t,
            tenant,
        ],
    )

    const getUserByToken = useCallback(async () => {
        if (!token) return

        try {
            const res = await GetPreConsumerByToken(token)

            if (res?.data) {
                const { data } = res?.data

                if (data) {
                    setPreDisabled(true)
                    setUserData(data)
                    fillPreForm(setValue, data)

                    disableContactFields(data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }, [token])

    useEffect(() => {
        if (token) {
            getUserByToken()
        }
    }, [token])

    const [timerStarted, setTimerStarted] = useState(false)

    const handleKeyUp = () => {
        if (!timerStarted) {
            setTimerStarted(true)
        }
    }

    const disableContactFields = (data) => {
        if (_.isEmpty(data?.phoneNumber2)) {
            setDisableItems({
                ...disableItems,
                phone: true,
            })
        }

        if (_.isEmpty(data?.email)) {
            setDisableItems({
                ...disableItems,
                email: true,
            })
        }
    }

    useEffect(() => {
        let typingTimer

        if (watch('listState')) {
            if (!timerStarted) {
                setTimerStarted(true)
            }

            clearTimeout(typingTimer)
            typingTimer = setTimeout(() => {
                onBlurStateRegistration(
                    watch,
                    setError,
                    clearErrors,
                    t,
                    setTimerStarted,
                )
            }, 3000)
        }

        return () => clearTimeout(typingTimer)
    }, [watch('stateRegistration'), watch('listState')])

    useEffect(() => {
        const cpfCnpj = replaceCpfCnpj(values?.cpf)
        if (!cpfCnpj || token) return

        setIsCnpj(cpfCnpj?.length > 11)
        validateCpf(values.cpf)
    }, [values.cpf])

    useEffect(() => {
        getTenantVersion()
    }, [])

    return (
        <Style.Container
            container
            item
            spacing={2}
            xs={12}
            mdMediaQuery={mdMediaQuery}
            disabled={preDisabled}
        >
            <Style.CustomTitle>
                <b>
                    {replaceCpfCnpj(values.cpf)?.length > 11
                        ? t('FIELD_COMPANY_DATA')
                        : t('FIELD_PERSONAL_DATA')}
                </b>
            </Style.CustomTitle>

            <Grid item xs={12} md={3} lg={3} sm={12} container>
                <Controller
                    as={
                        <RegisterInput
                            style={{ alignItems: 'flex-start' }}
                            xs={11}
                            md={11}
                            disabled={userCpf.blockCpf || preDisabled}
                            label={
                                generalRules.corporationPartners
                                    ? 'CPF/CNPJ'
                                    : 'CPF'
                            }
                            InputProps={{
                                inputComponent: generalRules.corporationPartners
                                    ? ResponsableCPFAndCNPJ
                                    : ResponsableCPF,
                                endAdornment: (
                                    <CustomEndAdornment
                                        style={{ paddingLeft: '10px' }}
                                    >
                                        {!!values.cpf?.length && (
                                            <div onClick={handleCpfRemove}>
                                                <i
                                                    className="icon-ico_excluir"
                                                    style={{
                                                        color: theme?.navigation
                                                            ?.buttons
                                                            ?.backgroundColor,
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </CustomEndAdornment>
                                ),
                            }}
                            error={userCpf?.errors || !!errors.cpf}
                            hasTooltip
                            helperText={t(
                                userCpf?.errors || errors?.cpf?.message,
                            )}
                        />
                    }
                    name="cpf"
                    control={control}
                    id="cpf"
                />
                <Grid
                    item
                    xs={1}
                    container
                    justify="center"
                    alignItems="center"
                    style={{ height: '54px' }}
                >
                    <SmToolTip
                        open={openTooltip}
                        title="Esse dado será usado no login do sistema."
                        arrow
                        placement={mdMediaQuery ? 'top' : 'right'}
                        onClose={() => setOpenTooltip(false)}
                    >
                        <Icon
                            onClick={() => {
                                setOpenTooltip(!openTooltip)
                            }}
                        >
                            info
                        </Icon>
                    </SmToolTip>
                </Grid>
            </Grid>
            <Controller
                as={
                    <RegisterInput
                        md={replaceCpfCnpj(values.cpf)?.length <= 11 ? 3 : 7}
                        label={
                            replaceCpfCnpj(values.cpf)?.length <= 11
                                ? t('FIELD_REGISTER_NAME')
                                : t('CORPORATE_NAME')
                        }
                        disabled={userCpf.blockCpf || (preDisabled && !token && tenant !== 'pocketexpress')}
                        error={!!errors.name}
                        helperText={t(errors?.name?.message)}
                        style={{ alignItems: 'flex-start' }}
                    />
                }
                name="name"
                control={control}
                id="name"
            />

            <Grid
                item
                xs={12}
                md={3}
                sm={12}
                lg={replaceCpfCnpj(values.cpf)?.length <= 11 ? 3 : 2}
                container
            >
                <Controller
                    as={
                        <RegisterInput
                            style={{ alignItems: 'flex-start' }}
                            label={
                                replaceCpfCnpj(values.cpf)?.length <= 11
                                    ? t('TITLE_REGISTER_BIRTHDAY')
                                    : t('DT_BASE')
                            }
                            xs={12}
                            md={12}
                            disabled={
                                userCpf.blockCpf
                                // || watch('disabledDateOfBirth')
                            }
                            error={!!errors.dateOfBirth}
                            helperText={t(errors?.dateOfBirth?.message)}
                            InputProps={{
                                inputComponent: DateMaskCustom,
                            }}
                        />
                    }
                    name="dateOfBirth"
                    control={control}
                    id="dateOfBirth"
                />
            </Grid>
            {/* {replaceCpfCnpj(values.cpf)?.length > 11 && (

            )} */}
            {replaceCpfCnpj(values.cpf)?.length > 11 && (
                <Style.CustomGrid item xs={12} md={12} container>
                    <Grid
                        item
                        xs={5}
                        md={2}
                        lg={2}
                        container
                        justify="flex-start"
                        alignItems="center"
                        className="containerWaiverCheckbox small-input-checkbox"
                        id="isStateDisabled-div"
                    >
                        <Controller
                            render={({ value, onChange }) => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <CustomCheckbox
                                                checked={Boolean(
                                                    values?.stateRegistrationWaiver,
                                                )}
                                                disabled={
                                                    preDisabled &&
                                                    !token &&
                                                    !watch('isStateDisabled')
                                                }
                                                onChange={(e) => {
                                                    onChange(!value)
                                                    setValue(
                                                        'stateRegistration',
                                                        '',
                                                    )
                                                    setValue('listState', '')
                                                    clearErrors(
                                                        'stateRegistration',
                                                    )
                                                    clearErrors('listState')
                                                }}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        }
                                        label={t('IE_EXEMPT')}
                                    />
                                )
                            }}
                            name="stateRegistrationWaiver"
                            id="stateRegistrationWaiver"
                            control={control}
                        />
                    </Grid>
                    <Controller
                        render={(props) => (
                            <Style.CustomGridState
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                container
                            >
                                <RegisterInput
                                    style={{ alignItems: 'flex-start' }}
                                    label={t('UF_STATE_REGISTRATION')}
                                    select
                                    xs={12}
                                    disabled={
                                        values?.stateRegistrationWaiver ||
                                        (preDisabled &&
                                            !token &&
                                            !watch('isStateDisabled'))
                                    }
                                    error={!!errors.listState}
                                    helperText={t(errors?.listState?.message)}
                                    list={listState}
                                    {...props}
                                />
                            </Style.CustomGridState>
                        )}
                        name="listState"
                        control={control}
                    />
                    <Controller
                        render={({ value, onChange }) => (
                            <RegisterInput
                                style={{ alignItems: 'flex-start' }}
                                xs={12}
                                md={3}
                                label={t('STATE_REGISTRATION')}
                                disabled={
                                    values?.stateRegistrationWaiver ||
                                    (preDisabled &&
                                        !token &&
                                        !watch('isStateDisabled'))
                                }
                                onKeyUp={handleKeyUp}
                                onChange={(e) => {
                                    if (e?.target?.value?.length > 30) return
                                    onChange(e.target.value)
                                }}
                                value={value}
                                name="stateRegistration"
                                hasTooltip
                                error={!!errors.stateRegistration}
                                helperText={t(
                                    errors?.stateRegistration?.message,
                                )}
                            />
                        )}
                        name="stateRegistration"
                        control={control}
                        id="stateRegistration"
                    />
                    <Grid
                        item
                        xs={12}
                        md={3}
                        lg={2}
                        container
                        className="containerWaiverCheckbox small-input-checkbox"
                        id="isStateDisabled-div"
                        style={{ alignItems: 'flex-start' }}
                    >
                        <FormControl>
                            <Controller
                                render={({ value, onChange }) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <CustomCheckbox
                                                    checked={Boolean(
                                                        values?.isMEI,
                                                    )}
                                                    disabled={
                                                        preDisabled && !token
                                                    }
                                                    onChange={(e) => {
                                                        onChange(!value)
                                                    }}
                                                    name="checkedB"
                                                />
                                            }
                                            label={t('YOUR_COMPANY_FITS_MEI')}
                                        />
                                    )
                                }}
                                name="isMEI"
                                id="isMEI"
                                control={control}
                            />
                        </FormControl>
                    </Grid>
                </Style.CustomGrid>
            )}
            {replaceCpfCnpj(values.cpf)?.length <= 11 && (
                <>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        style={{
                            marginTop: '8px',
                            alignItems: 'flex-start',
                        }}
                    >
                        <Controller
                            as={
                                <CustomSelect
                                    id="label"
                                    label="Sexo"
                                    error={errors.gender}
                                    errorMsg={
                                        errors.gender &&
                                        t(errors.gender.message)
                                    }
                                >
                                    <MenuItem value="M">Masculino</MenuItem>
                                    <MenuItem value="F">Feminino</MenuItem>
                                    <MenuItem value="NDA">
                                        Não informar
                                    </MenuItem>
                                </CustomSelect>
                            }
                            name="gender"
                            id="gender"
                            control={control}
                        />
                    </Grid>
                </>
            )}
        </Style.Container>
    )
}

export default memo(PersonalData)
